:root {
  font-size: @font-size-base;
}

body {
  min-height: 100vh;
}

.text-link {
  color: @accent-color;

  &:hover {
    color: @accent-color;
  }
}

.danger {
  color: @error-color;
}
// .text-green {
//   color: @accent-color;
//   &:hover {
//     color: @accent-color;
//   }
// }

.pf {
  //prefixed for specificity

  .ant-spin-container.ant-spin-blur {
    .ant-table-body {
      // padding-top: 60px;
      min-height: 63px;
    }
  }
  .app-root,
  .ant-layout {
    min-height: inherit;

    &-content {
      display: flex;
      flex-direction: column;
    }
  }

  .ant-tag {
    border: 0;
  }

  // Ant Tabs
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  .ant-card-head .ant-tabs-top {
    font-weight: 600;
  }

  .ant-form {
    legend {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      color: @base-color;
      font-weight: 600;
      padding-block-end: 6px;
      margin-block-end: 24px;
    }
  }

  .ant-select {
    color: @base-color;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-input-status-error {
    color: @error-color;
  }

  // Ant Modal
  .ant-modal-title {
    font-weight: 600;
  }

  .ant-modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
  }

  .ant-modal-footer .ant-alert {
    padding-inline: 32px;
    text-align: start;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    &::before {
      display: none;
    }

    // Colon ":" was not required as in design,
    // but asterisk "*" required on right side as in design.
    &::after {
      content: "*";
      display: inline-block;
      margin-inline-start: 4px;
      color: @error-color;
      font-size: 16px;
      font-family: "SimSun", sans-serif;
      line-height: 1;
    }
  }

  .ant-table-tbody {
    .ant-checkbox-inner {
      border-color: @btn-border-color;
    }
  }

  .ant-table-row-indent {
    margin-inline-end: -8px; // compensating for the flexbox gap
  }

  // Pagination
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: @gray400;
  }

  .ant-pagination-options {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 36px;
      border-color: @gray400;

      .ant-select-selection-item {
        line-height: 34px;
      }
    }
  }

  .ant-upload {
    &.ant-upload-drag .ant-upload {
      padding: 12px 0;
    }

    &.ant-upload-drag {
      background: #fff;
      border-style: solid;
    }
  }

  .tableStartSpace {
    padding-inline-start: 24px;
  }

  .tableStartSpaceExtra {
    padding-inline-start: 3%;
  }

  // .ant-drawer-header {
  //   border-bottom: none;
  // }

  .ant-drawer-header-title {
    flex-direction: row-reverse;

    .ant-drawer-close {
      margin-inline-end: 0;
    }
  }

  .ant-badge-dot {
    box-shadow: 0 0 0 2px #fff;
  }

  .ant-rate-star:not(:last-child) {
    margin-inline-end: 2px;
  }

  .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    background-color: @primary-light;
  }

  .h-auto {
    height: auto;
  }
}

.table-active_sale-row-bg {
  background-color: #fcfbf4 !important;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 5px;
    min-height: 30px;
    background-color: #d3c786;
    display: inline-block;
    inset-inline-start: 0;
    top: 0;
    bottom: 1px;
  }
}

.table-highlight-row-bg {
  background-color: #eaeaea !important;
  position: relative;
}

.new {
  display: inline-block;
  background-color: @error-color;
  color: #fff !important;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 2px 4px;
  margin-inline-start: 5px;
  margin-inline-end: 2px;
  font-size: 8px;
}

.shine {
  background: linear-gradient(120deg, #0000 33%, rgb(255 255 255 / 0.4) 50%, #0000 66%) @error-color;
  background-size: 300% 100%;
  animation: shine 2s infinite;
}

@keyframes shine {
  0% {
    background-position: right;
  }
}

.bodyScroll {
  overflow-y: auto !important;
}
